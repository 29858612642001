.forget_input {
  position: relative;
}
.word_eye {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
}
.password {
  z-index: -1;
}
.billing label {
  padding-bottom: 5px;
  padding-top: 10px;
}
