* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}
:root {
  --primary-color: #c4d4bc;
  --secondary-color: #545353;
  --third-color: #c8b084;
}
.blog_area {
  padding: 70px 0;
  background-color: #f8f9ff;
}
.bl_card {
  height: 700px;
  width: 80%;
  overflow: hidden;
  position: relative;
  float: right;
  border: none;
  margin: 20px 0;
}
.bl_card > .card-img-top {
  position: relative;
  height: 450px;
  overflow: hidden;
}
.bl_date {
  margin-left: 10px;
}
.bl_cont > h5 {
  font-size: 28px;
  font-weight: 600;
  padding: 10px 0;
}
.bl_cont .bl_date > i {
  background: transparent;
  padding: 0 5px;
  color: var(--secondary-color);
}
.bl_cont .bl_date > p {
  color: var(--secondary-color);
  font-size: 15px;
  padding: 2px;
}
.bl_cont > .card-text {
  font-size: 13px;
  font-weight: 500;
}
.bl_search {
  margin: 20px 0;
  width: auto;
  height: 50px;
  overflow: hidden;
  position: relative;
}
.bl_search > i {
  position: absolute;
  right: 0;
  padding: 10px 20px;
  background-color: var(--third-color);
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}
.bl_search > input {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  background-color: #eff0f1;
  border: 1px solid #e2c694;
}

.bl_search > input:focus,
.bl_search > input:active {
  border-color: #b89e71;
}

.related {
  width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
}
.related > h2 {
  font-size: 20px;
  color: #b89e71;
}
.bl_relates{
    padding: 10px 0;
}
.bl_relates > h3 {
  font-size: 20px;
  color: var(--secondary-color);
  line-height: 25px;
  padding-top: 20px;
}
.bl_relates > p {
  font-size: 13px;
}
.rel_date > i {
  font-size: 13px;
}


@media screen and (max-width:786px) {
    .bl_card{
        min-width: 100%;
        min-height: auto;
    }
}