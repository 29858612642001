.guest-section {
  margin-top: 100px;
  border: 1px solid black;
}
.security {
  border: 1px solid rgb(199, 198, 198);
  width: 58px !important;
  margin-bottom: 20px !important;
  padding: 5px;
  margin: 5px;
  border-radius: 4px;
}
.part {
  padding-top: 5%;
}
.security_Cont {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: fixed;
  background: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url("../../assets/instructors/5228369.jpg");
  background-size: cover;
}
@media screen and (max-width: 648px) {
  .security {
    width: 40px !important;
  }
  .part {
    padding-top: 20%;
  }
}
.loginForm .logo > img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
}
.login_btn {
  width: 100%;
  height: 50px;
  margin: 0 auto;
  display: block;
  font-size: 20px;
  background-color: #252525;
  color: #fff;
  border-radius: 1px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  border: none;
  transition: all 0.2s linear;
  font-weight: 600;
}
.login_btn::after,
.login_btn::before {
  position: absolute;
  content: "";
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}
.login_btn::before {
  top: 0;
  left: 0;
  width: 150%;
  height: 100%;
  z-index: -1;
  background-color: #c6d6bf;
  -webkit-transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}
.login_btn::after {
  opacity: 1;
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
  transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.login_btn:hover::before {
  opacity: 1;
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
  transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.login_btn:hover {
  color: #252525;
}

.loginForm {
  position: absolute;
  width: 450px;
  max-height: 550px;
  background-color: #fff;
  padding: 20px 10px;
  border-radius: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgba(202, 202, 202, 0.933);
  box-shadow: 0 2px 3px rgba(202, 202, 202, 0.933);
  transition: all 0.5s ease-in-out;
}

.form-control {
  border-color: #c4d4bc !important;
}
.logo-div {
  width: 50px;
}
.logo-nest {
  width: 100px;
  position: relative;
  left: -37px;
}
.rej-btn {
  background-color: #c4d4bc;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  width: 90px;
  margin-bottom: 20px;
  padding: 6px;
}
.leave {
  border: 1px solid#91998d;
  color: #777976;
  background-color: transparent;
  padding: 10px 20px;
  border-radius: 4px;
}
.error-box {
  padding-top: 50px;
}
.session_end_img {
  width: 100%;
  height: 300px;
  /* float: right; */
  overflow: hidden;
}
.session_end_img > img {
  max-height: 100%;
  height: 100%;
  object-fit: contain;
}
.session-cont {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.session__end_info {
  font-size: 1.05em;
  font-weight: 500;
}
.or_ {
  position: relative;
  width: 50%;
  text-align: center;
}
.or_::before {
  content: "";
  position: absolute;
  width: 100px;
  background-color: #252525;
  height: 2px;
  top: 50%;
}
.or_ > p {
  text-align: center;
}
