* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}
:root {
  --primary-color: #c4d4bc;
  --secondary-color: #545353;
  --third-color: #c8b084;
}
.event_sec {
  position: relative;
  padding: 70px 0;
}
.eve {
  padding: 40px 0;
}
.eve_container {
  position: relative;
}
.event_img {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.event_img > img {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transition: all 1s ease-in-out;
}
.event_img:hover > img {
  transform: scale(1.2);
}
.d_card {
  position: absolute;
  width: 120px;
  height: 120px;
  background-color: var(--third-color);
  left: 0;
  top: 0;
  padding: 30px 10px;
}
.d_card > #fst {
  font-size: 50px;
  margin: 0;
  text-align: center;
  font-weight: 700;
  color: #fff;
  line-height: 2.2rem;
}
.d_card > #sec {
  font-size: 40px;
  margin: 0;
  font-weight: 500;
  color: #fff;
  line-height: 40px;
  text-align: center;
  align-items: center;
  padding-left: 10px;
}
.event_cont > h2 {
  font-weight: 700;
  color: var(--secondary-color);
}
#date {
  font-size: 15px;
  color: #e8c141;
  font-weight: 500;
  margin: 0;
}
.event_cont > p {
  color: var(--secondary-color);
  font-size: 15px;
}
.event_cont > h4 {
  padding: 30px 0 0 0;
  font-weight: 600;
  color: var(--secondary-color);
}
.ev_bg1 {
  position: absolute;
  width: 500px;
  height: 100%;
  background-image: url("../../assets/bgg5.png");
  background-repeat: no-repeat;
  background-size: 100%;
  opacity: 0.2;
  left: 0;
  top: 0;
  z-index: -1;
}
.ev_bg2 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/bgg7.png");
  background-repeat: no-repeat;
  background-size: 100%;
  opacity: 0.2;
  bottom: 0;
  z-index: -1;
}
.ev_bg3 {
  position: absolute;
  width: 350px;
  height: 300px;
  background-image: url("../../assets/bgg3.png");
  background-repeat: no-repeat;
  background-size: 100%;
  opacity: 0.2;
  left: 0;
  bottom: 0;
  z-index: -1;
}

@media screen and (max-width: 786px) {
  .event_img {
    width: 100%;
    height: 300px;
  }
}

@media screen and (max-width: 500px) {
  .eve {
    margin: 10px;
    border: 1px solid var(--third-color);
    padding: 10px;
    border-radius: 10px;
  }
}
