* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}
.login_page {
  min-width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 10% 0;
  position: relative;
}
.log_area {
  position: relative;
  width: 65vw;
  height: 100%;
  background-color: #fff;
  border: 1px solid #e8eee5;
  margin: 0 auto;
  border-radius: 10px;
  overflow: hidden;
}
.log_img {
  min-width: 100%;
  min-height: 100%;
  background-color: #e8eee5;
  position: relative;
}
.log_img > img {
  width: auto;
  height: 350px;
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
}
.log_cont {
  width: 100%;
  height: auto;

  padding: 20px;
}
.log_logo {
  width: 100px;
  height: 100px;
  overflow: hidden;
  margin: 0 auto;
}
.log_logo > img {
  max-width: 100%;
  height: 100%;
}
.log_cont > h2 {
  text-align: center;
  padding: 10px;
}
.form_field {
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border-radius: 3px;
  border: 1px solid #c4d4bc;
}
.form_field:focus,
.form_field:active {
  border-color: #7f8c78;
}
.forget {
  text-decoration: none;
  color: #7f8c78;
  float: right;
}
#formm {
  width: 100%;
  font-weight: 500;
}
.signup_link {
  text-align: center;
  padding: 10px 0;
}
.signup_link > a {
  color: #3366cc !important;
  cursor: pointer;
  font-style: italic;
}
.or {
  position: relative;
  height: 20px;
  width: 50%;
  left: 50%;
  transform: translateX(-50%);
  margin: 20px 0;
}
.or::before {
  content: "";
  position: absolute;
  width: 50%;
  height: 2px;
  background-color: #7777;
  left: 50%;
  transform: translateX(-50%);
}
.or > span {
  position: absolute;
  top: -50%;
  left: 50%;
  background-color: #fff;
  width: 50px;
  text-align: center;
  transform: translateX(-50%);
}
#home {
  text-align: center;
}
#home > a {
  color: #7f8c78;
}
@media screen and (max-width: 1200px) {
  .log_img > img {
    height: 280px;
    bottom: 20%;
  }
}
@media screen and (max-width: 991px) {
  .log_img_main {
    display: none;
  }
}

@media screen and (max-width: 786px) {
  .log_area {
    width: 100%;
  }
}
.gotohome {
  position: absolute;
  bottom: 10px;
}
