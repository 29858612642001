@import url("https://fonts.googleapis.com/css2?family=Allura&display=swap");
* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}
:root {
  --primary-color: #c4d4bc;
  --secondary-color: #545353;
  --third-color: #c8b084;
}
.stream_head {
  width: 100%;
  height: 100px;
  background-color: transparent;
  margin: 0;
  padding: 15px;
  border-radius: 10px;
  position: relative;
}

.class_head {
  display: grid;
  grid-template-columns: 0.2fr 0.2fr;
  padding: 0;
  font-family: "Allura", cursive;
}
.stream_logo {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: relative;
  transition: all 0.5s ease-in-out;
}
.stream_logo > img {
  max-width: 100%;
}
.stream_title {
  padding: 10px;
}
.head_title {
  width: 500px;
  height: 30px;
  white-space: nowrap;
  font-size: 28px;
  cursor: pointer;
  color: var(--secondary-color);
  transition: all 0.5s ease-in-out;
}
.stream_title p > span {
  font-weight: 600;
  color: var(--third-color);
}
.btn_grp {
  width: 300px;
  padding: 10px;
  float: right;
}
.clss_btn {
  width: 70px;
  height: 50px;
  padding: 10px 12px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgb(200, 200, 200);
  color: #545353;
  margin: 0 5px;
  text-decoration: none;
  user-select: none;
  font-size: 15px;
}
.clss_btn:hover {
  color: #252525 !important;
}
.clss_btn:active {
  box-shadow: 0 2px 2px rgb(199, 199, 199);
}
.exit_class {
  padding: 10px;
  border-radius: 10px;
  background-color: var(--secondary-color);
  color: #fff;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 2px 5px rgb(200, 200, 200);
  margin: 0 5px;
  font-size: 15px;
}
.exit_class:hover {
  background-color: #ff2e2e;
  color: #fff;
}
.name-screen {
  position: absolute;
  background-color: #2525257d;
  color: #fff;
  border-radius: 20px;
  cursor: auto;
  z-index: 99;
  user-select: none;
}
.name-screen.main {
  padding: 5px 10px;
  left: 20px;
  top: 20px;
  font-size: 12px;
}
.name-screen.side {
  padding: 5px 10px;
  left: 10px;
  top: 10px;
  font-size: 12px;
}
