@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500&family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}
section {
  overflow: hidden;
}
:root {
  --primary-color: #c4d4bc;
  --secondary-color: #545353;
  --third-color: #c8b084;
}
.banner {
  padding: 70px 0;
}
.banner-sec {
  width: 100%;
  min-height: 700px;
  position: relative;
}
.lottie_bg {
  height: 500px;
  min-width: 600px;
  background-color: transparent;
  position: absolute;
  right: 20px;
  padding: 20px 0;
  transition: all 0.5s ease-in-out;
}
.lottie_bg div > svg {
  position: absolute;
  top: -10%;
}
.banner-cont {
  max-width: 700px;
  overflow: hidden;
}
.banner-cont > h1 {
  font-family: "Raleway", sans-serif;
  text-align: left;
  line-height: 4rem;
  font-weight: 700;
  font-size: 70px;
  color: #252525;
  text-transform: uppercase;
  user-select: none;
  padding: 60px 0 0 0;
  margin: 10px 0;
}
.banner-cont > p {
  font-size: 19px;
  margin-right: 20px;
  padding: 10px;
  line-height: 2.5rem;
  padding: 10px 0;
  color: var(--secondary-color);
}
.banner-cont p > span {
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}
.banner-cont p > span:hover {
  color: var(--third-color);
}
.nhs {
  color: #fff;
  width: calc(12vw + 6px);
  height: calc(5vw + 6px);
  background-image: linear-gradient(
    90deg,
    #00c0ff 0%,
    #ffcf00 49%,
    #fc4f4f 80%,
    #00c0ff 100%
  );
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  overflow: hidden;
}
.nhs::after {
  position: absolute;
  content: attr(alt);
  width: 12vw;
  height: 5vw;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nhs:hover {
  animation: slidebg 2s linear infinite;
}
.nhs > p {
  text-transform: uppercase;
  font-size: 20px;
  margin-top: 20px;
  font-weight: bold;
  color: #252525;
  z-index: 1;
}
@keyframes slidebg {
  to {
    background-position: 20vw;
  }
}
.course_btn {
  display: inline-block;
  position: absolute;
  width: 230px;
  height: 80px;
  padding: 22px;
  border-radius: 10px;
  background-color: #252525;
  color: #fff;
  text-decoration: none;
  margin-top: 5.5rem;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%) !important;
  overflow: hidden;
}
.course_btn::after,
.course_btn::before {
  position: absolute;
  content: "";
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}
.course_btn::before {
  top: 0;
  left: 0;
  width: 150%;
  height: 100%;
  z-index: -1;
  background-color: var(--primary-color);
  -webkit-transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}

.course_btn:hover::before {
  opacity: 1;
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
  transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.course_btn:hover {
  color: #252525;
}
.banner_ico {
  width: 700px;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
}
.col_c {
  margin: 0;
  padding: 0;
}
.banner-items {
  height: 250px;
  margin: 0;
  padding: 30px;
  border: none;
  transition: all 0.2s ease-in-out;
  border-radius: 0;
}

.c_row {
  --bs-gutter-x: -10.5rem;
}
.banner-items > .bc_img {
  width: 120px;
  height: 120px;
  margin: 0 auto;
  transition: all 0.2s ease-in-out;
  position: relative;
}
.bc_img > img {
  max-width: 100%;
}
.subb_title {
  text-align: center;
  font-size: 70px;
  font-weight: 400;
  z-index: 1;
}
.banner-items > .bc_img::after {
  content: "";
  position: absolute;
  height: 5px;
  width: 120px;
  border-radius: 50%;
  filter: blur(4px);
  background-color: #747474;
  left: 0;
  bottom: -10px;
  transition: all 0.2s ease-in-out;
}
.banner-items:hover > .bc_img::after {
  transform: translateY(5px);
  filter: blur(7px);
}
.banner-items:hover > .bc_img {
  transform: translateY(-10px);
}
.banner-items > p {
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 600;
  margin: 0 auto;
  padding: 30px 0;
  user-select: none;
  transition: all 0.2s ease-in-out;
  text-align: center;
}
.banner-items:hover > p {
  color: var(--third-color);
}
.programmes {
  padding: 20px 0;
}
.program_title {
  font-family: "Poppins", sans-serif;
  font-size: 70px;
  font-weight: 700;
  color: #7e8779;
  margin: 0 auto;
  text-align: center;
  padding: 70px 0 30px 0;
}
.program_sub {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2em;
  text-align: center;
  color: var(--secondary-color);
  margin-bottom: 0px;
}
.p_card {
  padding: 70px 0;
}
.pro_card1 {
  border-radius: 10px;
  border: 1px solid #e7e7e7;
  position: relative;
  margin-top: 5px;
  margin-bottom: 10px;
}
.pro_card1 > .card-img-top {
  position: relative;
  height: 250px;
  overflow: hidden;
}
.card-img-top > img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.overlay {
  position: absolute;
  background: #000;
  opacity: 0;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.card-img-top:hover .overlay {
  opacity: 0.5;
}
.banner_cont > p {
  transition: all 0.5s ease-in-out;
}
/* Frame top */
.vline {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 9;
  width: -webkit-calc(50% - 20px);
  width: -moz-calc(50% - 20px);
  width: calc(50% - 20px);
  height: -webkit-calc(50% - 20px);
  height: -moz-calc(50% - 20px);
  height: calc(50% - 20px);
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.vline::before {
  content: "";
  position: absolute;
  height: 1px;
  background-color: #fff;
  width: 0%;
  overflow: hidden;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}
.overlay:hover > .vline::before {
  width: 100%;
}
.vline:after {
  content: "";
  position: absolute;
  height: 0;
  background-color: #fff;
  width: 1px;
  overflow: hidden;
  -webkit-transition: height 0.5s ease-in-out;
  -moz-transition: height 0.5s ease-in-out;
  -o-transition: height 0.5s ease-in-out;
  transition: height 0.5s ease-in-out;
}
.overlay:hover > .vline::after {
  height: 100%;
}

/* Frame Bottom */
.vline-bottom {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 9;
  width: -webkit-calc(50% - 20px);
  width: -moz-calc(50% - 20px);
  width: calc(50% - 20px);
  height: -webkit-calc(50% - 20px);
  height: -moz-calc(50% - 20px);
  height: calc(50% - 20px);
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.vline-bottom::before {
  content: "";
  position: absolute;
  height: 1px;
  background-color: #fff;
  width: 0%;
  overflow: hidden;
  -webkit-transition: width 0.5s linear;
  -moz-transition: width 0.5s linear;
  -o-transition: width 0.5s linear;
  transition: width 0.5s linear;
  right: 0;
  bottom: 20px;
}
.overlay:hover > .vline-bottom::before {
  width: 100%;
}
.vline-bottom::after {
  content: "";
  position: absolute;
  height: 0;
  background-color: #fff;
  width: 1px;
  overflow: hidden;
  -webkit-transition: height 0.5s linear;
  -moz-transition: height 0.5s linear;
  -o-transition: height 0.5s linear;
  transition: height 0.5s linear;
  right: 0;
  bottom: 20px;
}
.overlay:hover > .vline-bottom::after {
  height: 100%;
}

.proCard {
  padding: 20px;
  height: auto;
}
.proCard > h5 {
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  text-align: left;
  font-size: 25px;
  user-select: none;
}
.proCard > p {
  font-size: 12px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  color: #747474;
  user-select: none;
}
.proCard i {
  max-width: 30px;
  -webkit-transition: max-width 1s;
  transition: max-width 0.5s;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  background-color: var(--primary-color);
  padding: 0 10px 0 0;
  border-radius: 4px;
}
.proCard i > span {
  margin-left: 10px;
  font-size: 20px;
  color: #252525;
  font-weight: 700;
}
.proCard i:hover {
  max-width: 100%;
}
.proCard i > svg {
  color: #252525 !important;
  font-size: 30px;
}

/* Trial section */
.trial {
  width: 100%;
  height: auto;
  background-color: #e4ebe0;
  margin: 20px 0;
  border-radius: 8px;
  padding: 80px 0;
  position: relative;
}
.trial::before {
  content: "";
  position: absolute;
  border-top: 76px solid transparent;
  border-right: 900px solid rgba(255, 255, 255, 0.54);
  position: absolute;
  bottom: 0;
  z-index: 9;
  width: 100%;
}
.form_area {
  width: 100%;
  height: 600px;
  background-color: #49545f;
  border-radius: 10px;
  position: relative;
}
.form_area > p {
  text-align: center;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  padding: 10px 0;
  width: 100%;
  background-color: #7d868d;
  font-style: italic;
}
.form_area p > span {
  font-weight: 600;
  font-size: 16px;
  text-decoration: underline;
}
.form_area > h2 {
  font-weight: 600;
  color: #fff;
  text-align: center;
  padding: 30px 0 0 0;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.1em;
}
.form_area > h4 {
  text-align: center;
  color: #fff;
  font-family: "Poppins", sans-serif;
  padding: 10px 0;
}
.trial_form {
  padding: 20px 50px;
}
.trial_form > .form-group {
  width: 100%;
  margin: 20px 0;
}
.trial_form .form-group > input {
  width: 100%;
  height: 60px;
  border-radius: 5px;
  background-color: #55616b;
  padding: 0 20px;
  font-size: 20px;
  outline: none;
  border: 1px solid #55616b;
  color: #c1c1c1;
  margin-bottom: 20px;
}
::placeholder {
  color: #c1c1c1;
}
.trial_form .form-group > input:focus {
  border: 1px solid var(--primary-color);
}
.form_btn {
  width: 200px;
  height: 50px;
  margin: 0 auto;
  display: block;
  font-size: 20px;
  background-color: #252525;
  color: #fff;
  border-radius: 1px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  border: none;
  transition: all 0.2s linear;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
.form_btn::after,
.form_btn::before {
  position: absolute;
  content: "";
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}
.form_btn::before {
  top: 0;
  left: 0;
  width: 150%;
  height: 100%;
  z-index: -1;
  background-color: var(--primary-color);
  -webkit-transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}

.form_btn:hover::before {
  opacity: 1;
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
  transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.form_btn:hover {
  color: #252525;
}

.react-tabs__tab-list {
  padding: 20px;
  border: none !important;
  background-color: #49515f;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  border-right: 1px solid #626a75;
}
.react-tabs__tab {
  border: none;
  background: transparent;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
}
.react-tabs__tab--selected {
  background: transparent;
  color: var(--third-color);
  border: none !important;
  text-decoration: none;
}
.tab-cont {
  padding: 20px;
}
.tab-cont > h2 {
  font-family: "Poppins", sans-serif;
  color: #fff;
  font-size: 25px;
  margin: 20px 0;
}
.tab-cont > p {
  font-size: 20px;
  color: #fff;
  float: none;
  text-align: left;
  width: 300px;
}
.video_player {
  position: relative;
  margin: 0 15px;
  float: right;
}
.icon-video {
  position: relative;
}
.icon-video span > i {
  position: absolute;
}

/* Instructor Section */
.instructor {
  padding: 30px 0;
}
.ins_card {
  margin: 50px 0;
  border-color: #e6f3ff;
  overflow: hidden;
}
.crImg {
  width: 100%;
  height: auto;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
}
.crImg > img {
  transition: all 0.5s ease-in-out;
}
.ins_card:hover .crImg > .card-img-top {
  transform: scale(1.2);
}
.ins_card .card-body > .card-title {
  font-size: 25px;
  padding: 5px 0;
  font-weight: 600;
  user-select: none;
  transition: all 0.5s ease-in-out;
}
.ins_card .card-body > .card-title:hover {
  color: var(--primary-color);
}
.ins_card .card-body > span {
  font-size: 20px;
  font-weight: 600;
  color: var(--secondary-color);
}
.ins_card .card-body > .card-text {
  font-size: 18px;
  padding: 10px 0;
}
.card-data {
  font-size: 14px;
  text-align: justify;
  padding: 10px 0;
}
.social a {
  color: var(--secondary-color);
  padding: 10px;
  margin: 0 auto;
  transition: all 0.2s ease-in-out;
}
.social a:hover {
  color: rgb(255, 68, 0);
}
.css-jpln7h-MuiTabs-scroller {
  background: #49515f;
  padding: 10px;
}
.programPanel {
  color: #fff !important;
  font-weight: 600 !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 16px !important;
  padding: 0 25px !important;
  border-right: 1px solid #626a75 !important;
}
.css-1aquho2-MuiTabs-indicator {
  display: none;
}
.programPanel.kidProgram {
  border: none !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: var(--third-color) !important;
}
.panel_cont {
  color: #fff;
}
.panel_img {
  width: 250px;
  height: 180px;
  float: right;
  overflow: hidden;
  position: relative;
}
.panel_img > img {
  max-width: 100%;
}
.video_layer {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  background: transparent;
  outline: none;
  border: none;
  font-size: 60px;
  color: var(--third-color);
  transition: all 0.2s ease-in-out;
}
.video_layer:hover {
  color: #ddb05c;
}
.events {
  padding: 50px;
  width: 100%;
  background-color: #f7f7f7;
}
.event_card {
  margin: 40px 0;
  overflow: hidden;
  max-height: 350px;
  border: 1px solid #e6f3ff;
  transition: all 0.6s ease-in-out;
  user-select: none;
}
.event_card > .eImg {
  max-height: 250px;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
}
.eImg > img {
  transition: all 0.6s ease-in-out;
}
.event_card:hover .eImg > img {
  transform: scale(1.1);
}
.event_card:hover {
  box-shadow: 0 0 5px rgb(170, 170, 170);
}
.event_card > .card-body {
  position: relative;
  padding: 0;
}
.ev_date {
  max-width: 100%;
  height: 100%;
  background-color: var(--primary-color);
  justify-content: center;
  text-align: center;
  padding: 30px 0;
  user-select: none;
}
.ev_date > h1 {
  font-size: 70px;
  font-family: "Poppins", sans-serif;
  line-height: 1.2rem;
  margin-top: 10px;
  user-select: none;
}

/* Teacher section */
.teachers {
  padding: 70px 0;
  margin-bottom: 20px;
}
.slick_slide {
  background-color: transparent;
  padding: 10px;
}
.slicks {
  width: 100%;
  height: 200px;
  background-color: #f6fbff;
  overflow: hidden;
  border: none;
  box-shadow: 0 1px 2px rgba(226, 226, 226, 0.933);
}
.slick-img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin: 10px;
}
.slick-img > img {
  max-height: 100%;
  object-fit: fill;
}
.slick_logo {
  position: absolute;
  left: 20%;
}
.slick_logo > a {
  margin: 5px 7px;
  color: var(--primary-color);
}
.slick_logo > a:hover {
  color: var(--secondary-color);
  transition: all 0.5s linear;
  -o-transition: all 0.5s linear; /* opera */
  -ms-transition: all 0.5s linear; /* IE 10 */
  -moz-transition: all 0.5s linear; /* Firefox */
  -webkit-transition: all 0.5s linear; /*safari and chrome */
}
.slick_name {
  font-size: 20px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
.slick_card-cont {
  font-size: 13px;
  width: 250px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  position: absolute;
  color: var(--secondary-color);
}
.slick-slider.slick-initialized {
  padding-bottom: 20px;
}
.slick-dots li button:before {
  background-color: var(--primary-color);
  height: 10px;
  width: 10px;
  text-indent: -9999px;
  overflow: hidden;
  border-radius: 50%;
}

/* Mobile view */
@media screen and (max-width: 1200px) {
  .banner-cont {
    padding: 50px 0 0 0;
    margin: 0 auto;
    font-size: 14px;
  }
  .banner-cont > h1 {
    width: 500px;
    font-size: 60px;
    line-height: 3.5rem;
    font-weight: 800;
  }
  .banner-cont > p,
  .banner-cont p span {
    font-size: 20px;
    line-height: 25px;
  }
  .lottie_bg {
    width: 230px;
    min-width: 250px;
  }
  .banner-items > p {
    font-size: 14px;
  }
  .program_title {
    font-size: 45px;
    padding: 20px 0;
  }
  .program_sub {
    font-size: 14px;
    max-width: 650px;
    margin: 0 auto;
  }
  .p_card {
    padding: 10px 0;
  }
  .css-jpln7h-MuiTabs-scroller {
    overflow-x: scroll !important;
  }
  .form_area {
    max-height: 500px;
  }
  .form_area > h2 {
    font-size: 20px;
  }
  .form_area > h4 {
    font-size: 15px;
  }
  .trial_form .form-group > input {
    height: 50px;
    font-size: 13px;
    margin-bottom: 7px;
  }
  .trial {
    height: auto;
  }
  .form_btn {
    width: 150px;
    height: 50px;
    font-size: 15px;
    font-weight: 400;
  }
  .trial_form {
    padding: 0px 20px;
  }
  .panel_img {
    height: 150px;
    width: 200px;
  }
  .panel_cont {
    font-size: 13px;
  }
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-size: 13px !important;
  }
  .ins_card {
    margin: 20px 0;
  }
  .ins_card .card-body > .card-title {
    font-size: 20px;
  }
  .ins_card .card-body > span {
    font-size: 15px;
    line-height: 10px;
    margin: 0;
  }
  .ins_card .card-body > .card-text {
    font-size: 15px;
    padding: 5px 0;
  }
  .card-data {
    font-size: 14px;
    text-align: justify;
    padding: 10px 0;
  }
  .card-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .card-ti {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
  }
  .ev_date > h1 {
    font-size: 40px;
  }
  .ev_date > h2 {
    font-size: 20px;
  }
  .event_card > .eImg {
    max-height: 150px;
  }
  .event_card > .card-body {
    height: 130px;
  }
  .slicks {
    height: 150px;
    padding: 10px;
  }
  .slick_name {
    font-size: 15px;
  }
  .slick-img {
    height: 50px;
    width: 50px;
  }
  .slick_card-cont {
    width: 170px;
  }
  .slickss > p {
    font-size: 13px;
  }
}
@media screen and (max-width: 992px) {
  .proCard > h5 {
    font-size: 18px;
  }
  .proCard > p {
    font-size: 15px;
  }
  .banner-cont > h1 {
    width: 100%;
    text-align: center;
  }
  .banner-cont > p,
  .banner-cont p span {
    text-align: center;
  }
  .lottie_bg {
    display: none;
  }
  .course_btn {
    display: inline-block;
    width: 180px;
    height: 50px;
    padding: 10px 20px;
    font-size: 15px;
    transition: all 0.2s ease-in-out;
  }
  .pro_card1 > .card-img-top {
    height: auto;
  }
  .crImg {
    height: auto;
  }
  .banner-items > p {
    font-size: 14px;
  }
  .program_title {
    font-size: 45px;
    padding: 20px 0;
  }
  .program_sub {
    font-size: 14px;
    max-width: 650px;
    margin: 0 auto;
  }
  .p_card {
    padding: 10px 0;
  }
  .css-jpln7h-MuiTabs-scroller {
    overflow-x: scroll !important;
  }
  .form_area {
    max-height: 500px;
  }
  .form_area > h2 {
    font-size: 20px;
  }
  .form_area > h4 {
    font-size: 15px;
  }
  .trial_form .form-group > input {
    height: 50px;
    font-size: 13px;
    margin-bottom: 7px;
  }
  .trial {
    height: auto;
  }
  .form_btn {
    width: 150px;
    height: 50px;
    font-size: 15px;
    font-weight: 400;
  }
  .trial_form {
    padding: 0px 20px;
  }
  .panel_img {
    height: 150px;
    width: 100%;
    float: initial;
    margin: 10px 0;
  }
  .panel_cont {
    font-size: 13px;
    display: -webkit-box;
    -webkit-line-clamp: 15;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    letter-spacing: 0.1rem;
  }
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-size: 13px !important;
  }
  .ins_card {
    margin: 20px 0;
  }
  .ins_card .card-body > .card-title {
    font-size: 20px;
  }
  .ins_card .card-body > span {
    font-size: 15px;
    line-height: 10px;
    margin: 0;
  }
  .ins_card .card-body > .card-text {
    font-size: 15px;
    padding: 5px 0;
  }
  .card-data {
    font-size: 14px;
    text-align: justify;
    padding: 10px 0;
  }
  .card-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ev_date > h1 {
    font-size: 40px;
  }
  .ev_date > h2 {
    font-size: 20px;
  }
  .event_card > .eImg {
    max-height: 150px;
  }
  .event_card > .card-body {
    height: 130px;
  }
  .slicks {
    height: 150px;
    padding: 10px;
  }
  .slick_name {
    font-size: 15px;
  }
  .slick-img {
    height: 50px;
    width: 50px;
  }
  .slick_card-cont {
    width: 170px;
  }
  .slickss > p {
    font-size: 13px;
  }
}
@media screen and (max-width: 768px) {
  .banner-sec {
    min-height: 700px;
  }
  .pro_card1 {
    margin: 10px 0;
  }
  .c_row {
    --bs-gutter-x: -1.5rem;
  }
  .events {
    padding: 0;
  }
  .event_card {
    width: auto;
    height: auto;
  }
  .lottie_bg {
    left: 50%;
  }
}

@media screen and (max-width: 500px) {
  .banner-sec {
    min-height: 550px;
  }
  .banner-cont > h1 {
    padding: 0;
    font-size: 40px;
    line-height: 40px;
    width: 250px;
    margin: 0 auto;
    transition: all 0.5s ease-in-out;
  }
  .banner-cont > p,
  .banner-cont p span {
    font-size: 15px;
    transition: all 0.5s ease-in-out;
  }
  .nhs {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: calc(40vw + 6px);
    height: calc(8vw + 6px);
  }
  .pro_card1 > .card-img-top {
    height: auto;
  }
  .events {
    padding: 0;
  }
  .event_card {
    width: auto;
    height: auto;
  }
  .program_title {
    font-size: 50px;
  }
  .course_btn {
    margin: 0;
  }
  .subb_title {
    font-size: 30px;
    font-weight: 600;
  }
  .form_area {
    margin-bottom: 20px;
  }
  .trial {
    height: auto;
  }
}
@media screen and (max-width: 600px) {
  .trial {
    height: auto;
  }
}

@media screen and (max-width: 300px) {
}
.head-h {
  color: white;
  font-weight: 600;
  text-align: center;
  font-size: 23px;
}
.notes {
  background-color: #e6f3ff;
}
.head-note {
  text-align: center;
  color: #34414d;
  font-size: 20px;
  margin-top: 10px;
}
.what-about {
  background-color: #c4d4bc;
  padding: 20px;
  height: auto;

  margin: 0px auto;
}
.what-about-head {
  color: #122335;
  text-align: center;
  font-size: 25px;
  margin-top: 20px;
  font-weight: 600;
}
.about-head-para {
  padding: 10px;
  margin-top: 20px;
  font-size: 16px;
  width: 80%;
  margin: 0px auto;
  text-align: justify;
  color: #545353;
}
.final-title {
  font-size: 22px;
  color: #34414d;
  text-align: center;
  margin-top: 20px;
  padding: 10px;
  font-weight: 600;
}
.final-para {
  font-size: 16px;
  padding: 10px;
  color: #8b7f7f;
  margin-bottom: 20px;
  margin-top: 10px;
  text-align: justify;
  width: 70%;
  margin: 0px auto;
}
.therapy-trial {
  margin-bottom: 10px;
  position: relative;
  top: -19px;
  font-size: 24px;
  font-weight: 600;
  color: #2c4258;
}
.final {
  background: #fff;
  margin-bottom: 60px;
}
.trail-card {
  background-color: #fdfdfd;
  padding: 15px;
  margin: 10px;
  min-height: 160px;
  padding-top: 15px;
  padding-bottom: 15px !important;
}
.blocked-data {
  display: block !important;
}
.blocked-data-img {
  width: 30px;
  height: 30px;
  position: relative;
}
.img-trial {
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.blocked-data-text {
  font-size: 15px;
  font-weight: 500;
  color: black;
  position: relative;
  top: 5px;
  left: 10px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  letter-spacing: 1px;
}
.trial-text {
  font-size: 14px;
  letter-spacing: 1px;
  position: relative;
  text-align: justify;
  top: 10px;
  margin-top: 10px;
  margin-bottom: 5px;
}
.side-div {
  width: 1px !important;
  /* margin-left: 3px;
  margin-right: 3px; */
  min-height: 200px;
  border: 1px solid #252525;
  padding: 0;
}
.why-boxes {
  position: relative;
  margin: 10px;
  top: 15px;
}
/* .data-head {
  padding: 3px;
  margin-right: 6px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: #000;
  position: relative;
  text-align: right;
} */
.para-wy {
  margin-left: 15px;
  font-size: 15px;
  letter-spacing: 1px;
  text-align: justify;
}

@media screen and (max-width: 767px) {
  .para-wy {
    position: relative;
    bottom: 187px;
    font-size: 14px;
  }

  .therapy-box {
    margin-top: -50px;
  }

  .why-boxes {
    margin-bottom: -79px;
  }

  .content-why {
    margin: auto;
  }

  .therapy-trial {
    font-size: 23px;
  }

  .data-head {
    font-size: 15px;
    margin-bottom: 17px;
  }

  .side-div {
    min-height: 182px;
  }
}
