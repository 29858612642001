@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500&family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");
:root {
  --primary-color: #c4d4bc;
  --secondary-color: #545353;
  --third-color: #c8b084;
}
* {
  font-family: "Poppins", sans-serif;
}
.therapies {
  padding: 120px 0;
}
.therapy_cont {
  max-width: 100%;
  padding: 40px 0;
  margin: 0 auto;
  transition: all 0.5s ease-in-out;
}
.therapy_card {
  height: 220px;
  border: 1px solid #c4d4bc54;
  border-radius: 4px;
  padding: 10px;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}
.therapy_card:hover {
  box-shadow: 0 2px 5px #7777;
  border: 1px solid #e1e1e1;
}
.therapy_img {
  position: relative;
  width: 100%;
  max-height: 200px;
  border-radius: 4px;
  overflow: hidden;
  background-color: #e7e8e9;
  transition: all 0.5s ease-in-out;
}
#img_load {
  object-fit: cover;
  width: 100%;
  height: 220px;
  transition: all 0.5s ease-in-out;
  z-index: 0;
}
#therapy_img {
  /* display: none; */
  z-index: 0 !important;
}
.img-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 0;
}
.therapy_title {
  font-size: 25px;
  padding: 20px 0 0 0;
}
.therapy_txt {
  font-size: 13px;
  color: #545353;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.price {
  user-select: none;
}
.price > span {
  font-size: 28px;
  color: #c8b084;
  padding-right: 5px;
}
.buy_card {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 30px 10px;
}
.buy_btn {
  position: absolute;
  width: 150px;
  padding: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #545353;
  color: #fff;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
}
.buy_btn_load {
  position: absolute;
  width: 150px;
  padding: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.buy_btn::after,
.buy_btn::before {
  position: absolute;
  content: "";
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}
.buy_btn::before {
  top: 0;
  left: 0;
  width: 150%;
  height: 100%;
  z-index: -1;
  background-color: var(--primary-color);
  -webkit-transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}

.buy_btn:hover::before {
  opacity: 1;
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
  transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.buy_btn:hover {
  color: #252525;
}
.modal_cont > h5 {
  font-size: 20px;
  color: #545353;
  margin: 20px 0;
}
.times {
  width: 100%;
  padding: 10px 15px;
  border-radius: 20px;
  border: 2px solid var(--primary-color);
  color: #252525;
  font-size: 13px;
  margin: 10px 10px 0 0;
}
.times.active {
  background-color: #c4d4bc;
}
.instructor_list {
  margin-top: 20px;
}
.instructor_list > select:focus,
.instructor_list > select:active {
  border-color: var(--primary-color);
}
.PopBtn_grp {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
}
.checkout_pop {
  width: 200px;
  display: block;
  padding: 10px;
  margin-top: 20px;
  margin-left: 10px;
  border-radius: 5px;
  font-weight: 500;
  background-color: var(--primary-color);
}
.checkout_pop.cancel {
  background-color: var(--secondary-color);
  color: #fff;
}
.ins_profile {
  display: flex;
  width: 300px;
  height: 0px;
  transition: height 0.5s ease-in-out;
  overflow: hidden;
}
.ins_profile.hide {
  height: 65px;
}
.ins_pic {
  width: 80px;
  height: auto;
  overflow: hidden;
  border-radius: 10px;
}
.ins_pic > img {
  max-width: 100%;
}
.ins_cont {
  padding: 0 20px;
}
hr {
  color: #b0b5ba !important;
}
#ins_title {
  color: var(--secondary-color);
  font-size: 20px;
  font-weight: 500;
}
#ins_cont {
  font-weight: 500;
  color: var(--secondary-color);
  font-size: 13px;
}
#ins_cont > span {
  font-size: 14px;
}
.err_modal {
  width: 100%;
  background-color: #f0d0d0;
  text-align: center;
  height: 0;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
}
.err_modal.show {
  height: auto;
}
.animation_preset {
  position: absolute;
  height: 200px;
  width: 200px;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);
}
.auth_error {
  width: 100%;
  position: absolute;
  left: 50%;
  bottom: 30%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.login_btn {
  position: absolute;
  padding: 10px 40px;
  background-color: #252525;
  color: #fff;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  margin-top: 10px;
  transition: all 0.5s ease-in-out;
}
.login_btn:hover {
  background-color: #040303;
}
/* .login_btn::after,
.login_btn::before {
  position: absolute;
  content: "";
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}
.login_btn::before {
  top: 0;
  left: 0;
  width: 150%;
  height: 100%;
  z-index: -1;
  background-color: var(--primary-color);
  -webkit-transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}

.login_btn:hover::before {
  opacity: 1;
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
  transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.login_btn:hover {
  color: #252525;
} */
.log_cont2 {
  position: absolute;
  width: 500px;
  background-color: #fff;
  padding: 30px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}
.log_cont2 > p {
  background-color: #f6cccc;
  padding: 10px;
}
.modal_wrapper > .modal_container {
  overflow-y: scroll;
}

.details {
  font-size: 15px;
  margin-bottom: 0.5rem;
  /* font-style: italic; */
}
.details > span {
  font-weight: 500;
  color: #545353;
  font-style: normal;
}
.therapy_category {
  color: #7a7a7a;
  font-size: 12px;
  font-weight: 600;
}

/* Calandar Style */
.calandar_card {
  overflow: hidden;
}
.react-calendar__navigation {
  width: 100%;
  background-color: transparent !important;
  height: 25px;
  border-bottom: 1px solid #d2d2d2;
}
.react-calendar__navigation__arrow,
.react-calendar__navigation__label__labelText,
.react-calendar__navigation__label {
  background: transparent !important;
  padding: 10px;
}
.react-calendar__month-view__weekdays__weekday {
  text-align: center;
}
.react-calendar__month-view__weekdays__weekday > abbr[title] {
  text-decoration: none !important;
  cursor: pointer !important;
  font-weight: 600;
}
.react-calendar__tile {
  padding: 10px;
  background: transparent;
}
.react-calendar__tile > abbr {
  font-size: 15px;
}
.react-calendar {
  border: none !important;
  width: 100% !important;
}
.react-calendar__tile {
  transition: all 0.2s ease-in-out;
}
.react-calendar__tile.react-calendar__tile--active {
  background-color: #c4d4bc !important;
}
.react-calendar__tile.react-calendar__tile--now {
  background-color: #c8b084;
  color: #fff;
}
.react-calendar__tile--now:hover {
  background-color: #a7936d !important;
}
.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  font-weight: 600;
  color: #252525;
}

/* Therpay Page Design */
.thpy {
  font-size: 15px;
  font-weight: 500;
  color: #7a7a7a;
}
.therapy_title_ {
  font-size: 35px;
}
.therapyCont {
  font-size: 13px;
}
.therapist_details_ {
  padding: 10px 20px;
}
.thp {
  font-size: 12px;
  line-height: 1.2em;
  text-align: center;
}
.thp.nm {
  font-size: 15px;
  font-weight: 600;
}
.tph_profile {
  display: block;
  width: 70px;
  height: 70px;
  background-color: #eee;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 10px;
  overflow: hidden;
}
.tph_profile > img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root,
.css-a1sg1c-MuiButtonBase-root-MuiButton-root {
  background-color: #c4d4bcb5 !important;
  color: #252525 !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #c8b084 !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #c4d4bc !important;
}
.btn_status {
  width: 100px !important;
  height: 40px !important;
}
.stepper-btn {
  background-color: var(--primary-color);
  border-radius: 5px;
}
.stepper-btn:disabled {
  background-color: #e0e7dd;
}
.btn_status > i {
  font-size: 20px;
  color: #000;
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover {
  cursor: pointer;
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  opacity: 0.5;
}
.slot_card {
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
}
.slot_card > #date {
  font-size: 15px;
  color: #747474;
  padding-bottom: 10px;
}
.slot_card > #time {
  font-weight: 500;
}
.slot_card.active {
  background-color: #c4d4bc;
  border: none;
}
.therapy_description {
  transition: all 0.2s ease-in-out;
}
.pro_btn {
  width: 100%;
  border: none;
  overflow: hidden;
  border-radius: 5px;
  transition: all 0.2s linear;
  font-weight: 500;
  font-size: 13px;
  white-space: nowrap;
  height: 45px;
}
.filter_item > .filter_nav.right {
  right: -52px;
}

.filter_nav {
  position: absolute;
  padding: 8px 12px;
  border-radius: 50%;
  margin-left: -54px;
}
.star {
  height: 20px;
  width: 20px;
  text-align: justify;
  margin-right: 5px;
}
.pop {
  display: block;
}
.therapy_description::-webkit-scrollbar {
  height: 7px;
  width: 5px;
}
.therapy_description::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #c4d4bc;
}
.therapy_description::-webkit-scrollbar-thumb {
  background-color: #b2b2b4;
  outline: #c4d4bc;
}
.react-calendar__tile:disabled {
  background-color: #c4d4bc26 !important;
}
.alert-msg {
  position: relative;
  opacity: 1;
  animation: fade 2s linear;
}

@media screen and (max-width: 600px) {
  .alert-msg {
    display: flex;
    font-size: 13px;
  }
}

.alert-msg > button {
  width: 50px !important;
  position: absolute;
  background-color: transparent;
  font-size: 20px;
  padding: 0;
  color: #819179;
  right: 10px;
  top: 10px;
  outline: none;
}

/* Calender indicater here */

.date-label {
  position: relative;
  color: red;
}
.date-label::before {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  background-color: #73e03c;
  border-radius: 50%;
  bottom: 3px;
  left: 50%;
  transform: translateX(-50%);
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
}
.filteredTherapies {
  transition: all 0.5s ease-in-out;
}
@media screen and (max-width: 1200px) {
  .therapy_description {
    width: 200px;
    font-size: 13px;
    height: 150px;
    overflow-y: scroll;
  }
  .slot_card > #date {
    font-size: 10px;
  }
  .slot_card > #time {
    font-size: 12px;
  }
  /* .filter_item ul {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding: 0;
    grid-gap: 2em;
    gap: 2em;
    margin-bottom: 30px;
    overflow-x: scroll;
} */

  .filter_item > .filter_nav.right {
    right: -47px;
    top: 0;
  }
  .filter_nav {
    position: absolute;
    padding: 8px 12px;
    border-radius: 50%;
    top: 0;
  }
}

@media screen and (max-width: 991px) {
  .therapy_description {
    width: 100%;
  }
  .therapy_cont {
    max-width: 750px;
  }
  .buy_btn {
    width: 110px;
    font-size: 15px;
  }
  .pro_btn {
    font-size: 11px;
    font-weight: 500;
    height: 40px;
  }
  .modal_wrapper > .modal_container {
    width: 750px;
  }
  .therapy_card {
    height: 100%;
  }
}
@media screen and (max-width: 786px) {
  .filter_nav {
    display: none;
  }
  .therapy_cont {
    max-width: 680px;
  }
  .buy_btn {
    width: 100px;
  }
  .modal_wrapper > .modal_container {
    width: 600px;
    height: 550px;
  }

  .react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
    padding: 0px !important;
    margin-bottom: 10px !important;
  }
  .react-calendar__navigation button {
    min-width: 36px !important;
    background: none;
  }
  /* .pro_btn{
    margin: 0;
    padding: 0;
    width: auto;
  }
  .filter_item ul{
    width: auto;
    margin: 0;
    padding: 0  ;
  } */
  .filter_item > ul {
    display: block;
  }
  .filter_item ul > li {
    margin: 10px;
  }
}
@media screen and (max-width: 500px) {
  .therapy_description {
    width: 100%;
  }
  .therapist_details_ {
    max-width: 100%;
  }
  .filter_nav {
    display: none;
  }
  .therapy_cont {
    max-width: 100%;
    margin: 0 10px;
  }
  .therapy_img > img {
    max-height: fit-content;
    max-width: 100%;
    max-height: 100%;
  }
  .therapy_title {
    text-align: center;
  }
  .therapy_category {
    text-align: center;
  }
  .therapy_txt {
    text-align: center;
  }
  .buy_btn {
    width: 150px;
  }
  .modal_wrapper > .modal_container {
    height: 500px;
  }
}
.confirm-step {
  position: absolute;
  right: 10px;
  top: 10px;
}
.therapy_Des {
  text-align: justify;
  color: #777575;
  width: 80%;
}
.therapy-bordered {
  text-align: center;
  margin: 0px auto;
  border: 1px solid #b1b1b1;
  border-radius: 12px;
  font-weight: 500;
}
.image-div {
  width: 100px;
  margin: 10px;
  height: 100px;
}
.ima-placeholder {
  width: 100%;
  border-radius: 50%;
  height: 100%;
}
.slot-list {
  padding-top: 10px;
}
.small-head {
  color: gray;
  font-size: 14px;
  text-align: center;
}
.inst-card-name {
  font-weight: 600;
}
.slot-card {
  background-color: #e9e9e9;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  padding: 10px;
  margin: 10px;
}
.slot-card:hover {
  background-color: #c4d4bc !important;
}
.new-data {
  padding-left: 20px;
}
