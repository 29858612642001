@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500&family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");
:root {
  --primary-color: #c4d4bc;
  --secondary-color: #545353;
  --third-color: #c8b084;
}

.main-construc {
  width: 100%;
  height: 80px;
  background-color:#fff;
  position: absolute;
  padding: 20px;
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
  z-index: 99;
  transition: all 0.5s ease-in-out;
 
}

.main-construc .scrolled{
  background-color: var(--primary-color) !important;
  position: fixed !important;
  top: 0 !important;
  width: 100%;
}
.logo_area {
  width: 150px;
  height: 100%;
  overflow: hidden;
  flex-grow: 1;
}
.logo_area a > img {
  max-width: 120px;
}
.profile_header {
  list-style: none;
  display: flex;
  text-align: center;
  text-decoration: none;
  margin-right: 2rem;
}
.profile_header li > a {
  color: var(--secondary-color);
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-family: "Poppins", sans-serif;
  height: 100%;
  font-size: 15px;
  font-weight: 500;
}
.profile_header {
  line-height: 40px;
  position: relative;
}
.profile_header::after {
  content: "";
  display: block;
  position: absolute;
  height: 2px;
  width: 0;
  background: transparent;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -ms-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
}
.profile_header:hover {
  color: var(--third-color);
}
#cl_{
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 0;
  color: var(--secondary-color);
  font-size: 15px;
}
@media screen and (max-width: 500px){
  .logo_area a > img {
    max-width: 70px;
}
.profile_header{
  font-size: 14px;
  padding: 0;
  margin: 0;
}
}

