@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500&family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");
:root {
  --primary-color: #c4d4bc;
  --secondary-color: #545353;
  --third-color: #c8b084;
}
/* nav{
  transition: all 1s ease-in-out;
  animation: fadeIn 2s; 
}

@keyframes fadeIn {
  0% { opacity: 1; }
  100% { opacity: 0; }
} */
.head-main {
  width: 100%;
  height: 80px;
  background-color: #fff;
  position: absolute;
  padding: 20px;
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
  z-index: 99;
  /* transition: all 0.5s ease-in-out; */
}
.head-main.scrolled {
  background-color: var(--primary-color) !important;
  position: fixed !important;
  top: 0 !important;
  width: 100%;
}
.avatar {
  position: relative;
}
.popUp {
  font-size: 12px;
  background-color: #c4d4bc;
  color: #000;
  width: 400px;
  margin: 0px auto;
  padding: 10px;
  position: relative;
  font-size: 14px;
  margin: 0px auto;
  right: 50%;
  border-radius: 4px;
  z-index: 999;
}
.pop-ms {
  color: #000;
  padding: 10px;
  font-size: 14px;
}
.clo-popup {
  margin-left: 10px;
}
.close-po {
  float: right;
  position: relative;
  top: -5px;
  font-size: 18px;
}
.container {
  position: relative;
}

.not-round {
  position: absolute;
}

.avatar.placeholder {
  background-image: url("../../assets/placeholder.webp");
  background-size: cover;
  margin-left: 20px;
}
.auth-links {
  display: flex;
  font-size: 15px;
  margin-left: 20px;
  margin-top: 5px;
  font-weight: 500;
  color: #545353;
  /* transition: all 0.2s ease-in-out; */
}

@media screen and (max-width: 600px) {
  .auth-links {
    text-align: center;
    margin-top: 10px;
  }
}
.notification_ic {
  position: relative;
  top: 6px;
  color: rgb(226, 112, 112);
  animation: slideIn 0.5s ease-in-out;
}

@keyframes slideIn {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
.not-round {
  background-color: red;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  top: 0px;
  position: absolute;
}
