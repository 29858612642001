:root {
  --primary-color: #c4d4bc;
  --secondary-color: #545353;
  --third-color: #c8b084;
}
input[text]:focus {
  box-shadow: none !important;
}
.check_head {
  position: fixed;
  width: 100%;
  height: 60px;
  background-color: var(--primary-color);
  padding: 15px 0;
  overflow: hidden;
  z-index: 99;
}
.checkout_main {
  padding: 60px 0;
  background-color: #fff;
  margin: 0px auto;
}
.billing {
  height: 100%;
  max-width: 900px;
  overflow: hidden;
  margin: 0 auto;
}
.checkout_form {
  padding: 10px 0 0 0;
}
.payment {
  position: relative;
  display: flex;
  width: 100%;
  height: 50px;
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 5px;
  border: 1px solid #ced4da;
  margin: 5px 0;
  overflow: hidden;
}
.payment > label {
  padding: 2px 10px;
}
/* .payment_ico {
  position: absolute;
  width: 200px;
  height: 100%;
  right: 0;
  top: 0;
  background-image: url("../../assets/payment.png");
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: 50%;
} */
.payment_ico1 {
  position: absolute;
  width: 120px;
  height: 100%;
  left: 0;
  top: 0;
  background-image: url("../../assets/stripe.png");
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: 50%;
}
#payment_name {
  font-weight: 600;
  color: #635bff;
}
.payment > i {
  position: absolute;
  font-size: 20px;
  color: #545353;
  padding: 0;
  right: 20px;
}
.termsconditions {
  user-select: none;
  font-size: 15px;
  padding: 20px 0;
}
.checkout_btn {
  width: 100%;
  padding: 10px 0;
  background-color: #635bff;
  color: #fff;
  font-size: 18px;
  border: none;
}
.checkout_btn:hover {
  background-color: #534bee;
  color: #fff;
}
.form-select:focus {
  box-shadow: none;
}
.billing_card {
  padding: 20px;
  margin: 20px 0;
}
table {
  margin: 10px 0 0 0;
}
table,
tr,
td {
  color: #545353;
  font-weight: 500;
  padding: 5px 0;
  user-select: none;
}
#bill_title {
  color: #252525;
}
.amount {
  color: #252525;
  padding: 10px 0;
  font-size: 25px;
}
.credit_card {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin: 7px;
  padding: 7px 0;
  transition: height 0.5s ease-in-out;
}
.credit_card.hide {
  height: 0px;
}
.no_Auth {
  width: 100%;
  padding: 20px 0;
  overflow: hidden;
}
.no_Auth > h2 {
  font-size: 18px;
  padding: 20px 0 0 0;
}
.login_ {
  position: relative;
  width: 100%;
  height: 50px;
  background-color: #252525;
  color: #fff;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  margin-top: 10px;
  transition: all 0.5s ease-in-out;
}
.login_::after,
.login_::before {
  position: absolute;
  content: "";
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}
.login_::before {
  top: 0;
  left: 0;
  width: 150%;
  height: 100%;
  z-index: -1;
  background-color: var(--primary-color);
  -webkit-transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}

.login_:hover::before {
  opacity: 1;
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
  transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.login_:hover {
  color: #252525;
}
.no_Auth > h5 {
  position: relative;
  text-align: center;
  padding: 20px 0 0 0;
}
.no_Auth > h5::before {
  content: "";
  position: absolute;
  width: 40%;
  height: 1px;
  background-color: #7777;
  left: 0;
  top: 70%;
}
.no_Auth > h5::after {
  content: "";
  position: absolute;
  width: 40%;
  height: 1px;
  background-color: #7777;
  right: 0;
  top: 70%;
}
.back_btn {
  background-color: #d7e0d3;
  border: none;
  outline: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.sub-payment {
  height: 40px;
  width: 100%;
  color: white;
  border-radius: 4px;
  background-color: #252525;
  margin-top: 20px;
  transition: all 0.2s ease-in-out;
}
.sub-payment:hover {
  background-color: #363333;
}
.or_line{
  position: relative;
  height: auto;
  width: 50%;
  text-align: center;
  padding: 10px 0;
  font-weight: 500;
  color: #363333;
  font-size: 15px;
  margin: 0 auto;
}
.or_line > .txt{
  position: absolute;
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  padding: 0 10px;
}
.or_line::after{
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #252525;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
}