@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500&family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --primary-color: #c4d4bc;
  --secondary-color: #545353;
  --third-color: #c8b084;
}
.head-top {
  width: 100%;
  height: 80px;
  background-color: var(--primary-color);
  position: absolute;
  padding: 20px;
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
  z-index: 99;
  overflow: hidden;
}
.logo_area {
  width: 150px;
  height: 100%;
  overflow: hidden;
  flex-grow: 1;
}
.logo_area a > img {
  max-width: 120px;
}
.nav_items {
  list-style: none;
  display: flex;
  text-align: center;
  margin-right: 2rem;
}
.nav_items li > a {
  color: var(--secondary-color);
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-family: "Poppins", sans-serif;
  height: 100%;
  font-size: 15px;
  font-weight: 500;
}
.nav_links {
  line-height: 40px;
  position: relative;
}
.nav_links::after {
  content: "";
  display: block;
  position: absolute;
  height: 2px;
  width: 0;
  background: transparent;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -ms-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
}
.nav_links:hover::after {
  width: 100%;
  background-color: var(--third-color);
}
.nav_links.selected {
  color: var(--third-color);
}
.nav-icon {
  display: none;
  z-index: 20;
  }

/* Nav2 */
.nav_items2 {
  list-style: none;
  display: flex;
  text-align: center;
  margin-right: 2rem;
}
.nav_items2 li > a {
  color: var(--secondary-color);
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-family: "Poppins", sans-serif;
  height: 100%;
  font-size: 15px;
  font-weight: 500;
}
.nav_links2 {
  line-height: 40px;
  position: relative;
  user-select: none;
  overflow: hidden;
  margin: 0 10px;
}
.nav_links2::after {
  content: "";
  display: block;
  position: absolute;
  height: 2px;
  width: 0;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  background: transparent;
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -ms-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}
.nav_links2:hover::after {
  width: 100%;
  background-color: var(--third-color);
}
.nav_links2.selected {
  color: var(--third-color);
}
.drop_menu{
  height: 0px;
  background-color: #eee;
  font-size: 15px;
  list-style: none;
  transition: height 0.5s ease-in-out;
  overflow: hidden;
  text-align: center;
  padding: 0;
  position: absolute;
  left: 10%;
}
.nav_pro:hover .drop_menu{
  height:100px;
}
.drop_menu a{
  text-decoration: none;
  color: var(--secondary-color);
}
.drop_menu a > li{
  padding: 5px 10px;
}
.mob_pro{
  user-select: none;
  position: relative;
  width: 100%;
}
.mob_nav{
  width: 100%;
  height: 0px;
  position: absolute;
  list-style: none;
  padding: 0;
  background-color: #fff;
  overflow: hidden !important;
  transition: none;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
}
.mob_nav.show{
  height: 100px;
}
.mob_nav a{
  text-decoration: none;
  color: var(--secondary-color);
}
.mob_nav a > li{
  padding:2px;
}
.head-top.scrolled {
  background-color: var(--primary-color) !important;
  position: fixed !important;
  top: 0 !important;
  width: 100%;
}
.cart{
  position: relative;
}
.cart_menu{
  height: auto;
  width: 200px;
  background-color: #fff;
  box-shadow: 0 2px 3px #eee;
  position: absolute;
  right: 0;
  opacity: 0;
  transition: all 0.5s linear;
  padding: 10px;
  border-radius: 7px;
  font-size: 13px;
  top: 50px;
  z-index: 99;
}
.cart_menu.show{
  opacity: 1;
}
.cart_menu > p{
  color: rgba(204, 204, 204, 0.933);
}
.cart_btn{
  border: none;
  outline: none;
  background: transparent;
  color: #545353;
  font-size: 25px;
  margin-left: 20px;
}
.search-box{
  width: 200px;
  border: 1px solid var(--primary-color);
  background-color: #fff;
  border-radius: 10px;
  padding: 0;
  font-size: 15px;
  overflow: hidden;

}
.search-box > input{
  width: 80%;
  height: 100%;
  outline: none;
  border: none;
  padding: 0 15px;
}
.search-box > i{
  font-size: 18px;
  color: #888888;
  padding: 10px;
  cursor: pointer;
}
.profile{
  float: right;
  width: 150px;
  background-color: transparent;
}
.avatar{
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 auto;
  background-image: url('../../assets/avatar2.png');
  background-size: cover;
  background-position-x: 40px;
  overflow: hidden;
}
.avatar > img{
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
}
.profile_details{
  width: 100%;
  visibility: hidden;
  opacity: 0;
  padding: 10px;
  background-color: #fff;
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgb(209, 209, 209);
  overflow: hidden;
  transition: all 0.5s linear;
  position: relative;
}
.profile_details > a{
  cursor: pointer;
  text-align: center;
  font-size: 15px;
  text-decoration: none;
  padding: 5px;
  color: var(--secondary-color);
}
.profile_details a > i{
  text-align: left;
}
.profile_details.show{
  opacity: 1;
  visibility: visible;
}
#cl_{
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 0;
  color: var(--secondary-color);
  font-size: 15px;
}
@media screen and (max-width: 500px){
  .logo_area a > img {
    max-width: 70px;
}
.auth-links{
  font-size: 14px;
  margin-left: 0 !important;
}
.nav-icon{
  height: 20px;
  width: 20px;
  font-size: 25px;
}
}
@media screen and (max-width: 992px) {
  .search-box{
    display: none;
  }
  .nav-icon {
    display: block;
  }
  .nav_items {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 80px;
    opacity: 1;
    padding: 30px 0;
  }
  .nav_items2{
    display: none;
  }
  .nav_items.active {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease-in-out;
    z-index: 1;
  }
  .logo_area{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .profile{
    left: 10px;
  }
  .nav_links::after{
    display: none;
  }
  .search-box > input{
    width: 50%;
  }
  .search-box > i{
    padding: 0;
  }
}

@media screen and (max-width:1200px) {
 .profile{
  position: relative;
  height: 160px;
  top: 0;
  padding: 0;
 }
 .search-box > input{
  width: 80%;
}
.search-box > i{
  padding: 0;
}
}